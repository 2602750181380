import React from "react";
import { Link } from "react-router-dom";
import { StoryData } from "../data";
import { LightBlueButton } from "./Buttons/LightBlueButton.jsx";
import { FaCircle } from "react-icons/fa";

function Professionals(props) {
  return (
    <div className="story-template">
      <div className="wrapper professionals-icon">
        <h2>Fagfólk</h2>
        <FaCircle />
      </div>
      <div className="no-overflow">
        {StoryData[props.location.state.randomStoryIndex].story.map(
          (word, key) => {
            // If both have marked
            if (
              StoryData[
                props.location.state.randomStoryIndex
              ].professionals.includes(key) &&
              props.location.state.wordArr.includes(key)
            ) {
              return (
                <span key={key} className="both-line">
                  {word}
                  <span> </span>
                </span>
              );
            }

            // If professionals have marked
            else if (
              StoryData[
                props.location.state.randomStoryIndex
              ].professionals.includes(key)
            ) {
              return (
                <span key={key} className="professional-line">
                  {word}
                  <span> </span>
                </span>
              );
            }

            // If only user has marked
            else if (props.location.state.wordArr.includes(key)) {
              return (
                <span key={key} className="user-line">
                  {word}
                  <span> </span>
                </span>
              );
            }
            // If nobody marked
            else {
              return <span key={key}>{word} </span>;
            }
          }
        )}
      </div>
      <Link
        to={{
          pathname: "/information",
          state: {
            randomStoryIndex: props.location.state.randomStoryIndex,
            wordArr: props.location.state.wordArr,
          },
        }}
      >
        <LightBlueButton>Af hverju?</LightBlueButton>
      </Link>
    </div>
  );
}

export default Professionals;
