import React, { Component } from "react";
import { Link } from "react-router-dom";
import { DarkBlueButton } from "./Buttons/DarkBlueButton.jsx";
import tutorialimg from "../assets/tutorial-img.png";
import Vidvorun from "../vidvorun.png";
class Tutorial extends Component {
	render() {
		return (
			<div className="container page-template">
				<div className="content-warning">
					<p className="content-warning-title">
						<strong>Efnisviðvörun</strong>
						<img
							className="vidvorun-img"
							src={Vidvorun}
							alt="Logo"
						/>
					</p>
					<p>
						Gott er að hafa í huga að sumar sögur gætu innihaldið
						upplýsingar sem gætu valdið uppnámi
					</p>
				</div>
				<div className="tutorial-div">
					<img
						className="tutorial-img"
						src={tutorialimg}
						alt="Logo"
					/>
					<p>
						Strikaðu yfir þá hegðun sem þér finnst fara yfir strikið
					</p>
				</div>
				<Link to={"/story"}>
					<DarkBlueButton>Byrja</DarkBlueButton>
				</Link>
			</div>
		);
	}
}

export default Tutorial;
