import React from "react";
import styled from "styled-components";

const Button = styled.button`
	background-color: #efefef;
	width: 50px;
	height: 50px;
	border-color: #8dca8c;
	border-radius: 50%;
	border-width: medium;
	//border: none;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 32px;
	color: #8dca8c;
`;

export const ReloadButton = ({ children, className, onClick }) => {
	return (
		<Button onClick={onClick} className={className}>
			{children}
		</Button>
	);
};
