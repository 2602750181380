export const StoryData = [
	{
		id: 0,
		story: [
			"Ég",
			"var",
			"14",
			"ára",
			"og",
			"hann",
			"16.",
			"Ég",
			"átti",
			"aðra",
			"vini",
			"utan",
			"við",
			"sambandið",
			"en",
			"í",
			"hvert",
			"skipti",
			"sem",
			"ég",
			"hékk",
			"eitthvað",
			"með",
			"þeim",
			"varð",
			"hann",
			"reiður",
			"og",
			"sagði",
			"kannski",
			"“þú",
			"elskar",
			"mig,",
			"er",
			"það",
			"ekki”",
			"til",
			"að",
			"ég",
			"myndi",
			"bara",
			"vera",
			"með",
			"honum.",
			"Hann",
			"var",
			"alltaf",
			"að",
			"biðja",
			"mig",
			"um",
			"eitthvað",
			"tengt",
			"kynlífi,",
			"og",
			"stundum",
			"var",
			"ég",
			"kannski",
			"bara",
			"ekki",
			"í",
			"stuði",
			"eða",
			"fannst",
			"það",
			"sem",
			"hann",
			"var",
			"að",
			"biðja",
			"um",
			"vera",
			"óþægilegt.",
			"Þá",
			"hundsaði",
			"hann",
			"mig",
			"bara",
			"inni",
			"í",
			"herberginu",
			"hans",
			"í",
			"marga",
			"klukkutíma.",
			"Svo",
			"sátum",
			"við",
			"bara",
			"í",
			"þögn",
			"þangað",
			"til",
			"ég",
			"gafst",
			"upp.",
		],
		professionals: [
			14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
			31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 54, 55, 56, 57,
			58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74,
			75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91,
			92, 93, 94, 95, 96,
		],
		explanation:
			"Við strikum yfir þarna af því að þarna er kærastinn að stýra því hvað hún gerir. Svona fýlustjórnun til að einangra eða þvinga fram kynlíf er ofbeldi. ",
	},
	{
		id: 1,
		story: [
			"Ég",
			"hafði",
			"átt",
			"kærasta",
			"áður,",
			"stráka",
			"sko,",
			"en",
			"þetta",
			"var",
			"í",
			"fyrsta",
			"sinn",
			"sem",
			"ég",
			"var",
			"með",
			"stelpu.",
			"Það",
			"var",
			"bara",
			"allt",
			"annað",
			"og",
			"ég",
			"var",
			"svo",
			"sjúklega",
			"ástfangin.",
			"Það",
			"var",
			"langt",
			"síðan",
			"hún",
			"kom",
			"út",
			"úr",
			"skápnum",
			"og",
			"hún",
			"vissi",
			"einhvern",
			"veginn",
			"miklu",
			"meira",
			"um",
			"allt",
			"hinsegin",
			"og",
			"þekkti",
			"fleira",
			"hinsegin",
			"fólk.",
			"Við",
			"fórum",
			"svo",
			"eiginlega",
			"að",
			"vera",
			"bara",
			"með",
			"hennar",
			"vinum",
			"og",
			"fljótlega",
			"stýrði",
			"hún",
			"öllu",
			"sem",
			"við",
			"gerðum.",
			"Ég",
			"fór",
			"að",
			"skammast",
			"mín",
			"smá",
			"fyrir",
			"að",
			"vera",
			"með",
			"vinum",
			"mínum",
			"sem",
			"eru",
			"ekki",
			"hinsegin",
			"því",
			"hún",
			"var",
			"alltaf",
			"að",
			"setja",
			"út",
			"á",
			"þau.",
		],
		professionals: [
			53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69,
			70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86,
			87, 88, 89, 90, 91, 92, 93, 94, 95,
		],
		explanation:
			"Við strikum þarna yfir af því að þarna er valdaójafnvægi í sambandinu, önnur þeirra stýrir meira en hin. Það er erfitt að lesa úr sögunni hvort þetta eru óheilbrigð samskipti eða ofbeldi en þetta er mikilvægt að laga.",
	},
	{
		id: 2,
		story: [
			"Ég",
			"verð",
			"alveg",
			"stundum",
			"of",
			"reiður",
			"en",
			"það",
			"verða",
			"það",
			"allir.",
			"Fyrverandi",
			"kærastan",
			"mín",
			"forðaðist",
			"mig",
			"stundum",
			"og",
			"sagði",
			"mér",
			"ekki",
			"hvert",
			"hún",
			"var",
			"að",
			"fara.",
			"Það",
			"lét",
			"mér",
			"líða",
			"eins",
			"og",
			"hún",
			"væri",
			"að",
			"halda",
			"framhjá",
			"þannig",
			"að",
			"ég",
			"fór",
			"ég",
			"að",
			"leita",
			"að",
			"henni",
			"eða",
			"fylgjast",
			"með",
			"á",
			"SC",
			"maps",
			"hvar",
			"hún",
			"væri.",
			"Einu",
			"sinni",
			"fór",
			"ég",
			"síðan",
			"og",
			"fann",
			"hana",
			"og",
			"þá",
			"var",
			"ég",
			"orðin",
			"svo",
			"reiður",
			"að",
			"ég",
			"snappaði",
			"á",
			"hana",
			"og",
			"öskraði.",
			"Sagði",
			"henni",
			"að",
			"það",
			"væri",
			"ömurlegt",
			"að",
			"eiga",
			"kærustu",
			"eins",
			"og",
			"hana",
			"sem",
			"segir",
			"manni",
			"ekki",
			"hvar",
			"hún",
			"er",
			"eða",
			"með",
			"hverjum",
			"og",
			"að",
			"hún",
			"gerði",
			"beisiklí",
			"aldrei",
			"neitt",
			"fyrir",
			"mig.",
			"Hún",
			"lét",
			"eins",
			"og",
			"ég",
			"ætlaði",
			"að",
			"lemja",
			"hana",
			"og",
			"hún",
			"þyrfti",
			"bara",
			"að",
			"vera",
			"hrædd",
			"við",
			"mig.",
			"Það",
			"var",
			"alveg",
			"erfitt",
			"að",
			"fatta",
			"það",
			"en",
			"ég",
			"held",
			"að",
			"ég",
			"hafi",
			"alveg",
			"farið",
			"yfir",
			"strikið",
			"í",
			"okkar",
			"sambandi",
			"og",
			"það",
			"oftar",
			"en",
			"einu",
			"sinni.",
		],
		professionals: [
			14, 15, 16, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58,
			59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75,
			76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92,
			93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107,
			108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125,
		],
		explanation:
			"Við strikum yfir þarna af því að þarna er hann að réttlæta eða gera lítið úr atvikunum þó hann viti að hann gekk of langt. Þegar hann er að hræða aðra vegna reiði þarf hann að skoða eigin hegðun og taka fulla ábyrgð.",
	},
	{
		id: 3,
		story: [
			"Þegar",
			"ég",
			"var",
			"um",
			"12",
			"ára",
			"var",
			"strákur",
			"með",
			"mér",
			"í",
			"körfuboltanum",
			"sem",
			"var",
			"rosalega",
			"stelpulegur.",
			"Við",
			"vorum",
			"alltaf",
			"aðeins",
			"að",
			"stríða",
			"honum",
			"á",
			"því.",
			"Það",
			"byrjaði",
			"frekar",
			"saklaust,",
			"við",
			"kölluðum",
			"hann",
			"kannski",
			"stundum",
			"stelpu",
			"eða",
			"homma.",
			"En",
			"svo",
			"á",
			"endanum",
			"var",
			"hann",
			"eginlega",
			"aldrei",
			"kallaður",
			"annað",
			"en",
			"homminn",
			"og",
			"einhverjir",
			"farnir",
			"að",
			"taka",
			"stundum",
			"fötin",
			"hans",
			"og",
			"alls",
			"konar.",
			"Hann",
			"entist",
			"ekkert",
			"lengi",
			"og",
			"hætti",
			"að",
			"æfa",
			"með",
			"okkur",
			"áður",
			"en",
			"tímabilið",
			"kláraðist.",
		],
		professionals: [0],
		explanation: "útskýringartexti",
	},
	{
		id: 4,
		story: [
			"Ég",
			"var",
			"nýorðinn",
			"18",
			"ára",
			"og",
			"hann",
			"líka.",
			"Við",
			"þekktumst",
			"úr",
			"skólanum",
			"og",
			"byrjuðum",
			"að",
			"tala",
			"saman",
			"online.",
			"Vorum",
			"búnir",
			"að",
			"adda",
			"hvor",
			"öðrum",
			"á",
			"öllum",
			"miðlum",
			"og",
			"spjallið",
			"leiddi",
			"út",
			"í",
			"daður.",
			"Hann",
			"fór",
			"svo",
			"að",
			"vera",
			"alltaf",
			"að",
			"mana",
			"mig",
			"til",
			"að",
			"gera",
			"alls",
			"konar",
			"kynferðislegt",
			"og",
			"senda",
			"myndir",
			"af",
			"því.",
			"Það",
			"var",
			"oft",
			"skrítið",
			"að",
			"hann",
			"væri",
			"að",
			"mana",
			"mig",
			"en",
			"ekki",
			"bara",
			"spyrja.",
			"Stundum",
			"var",
			"hann",
			"líka",
			"að",
			"segja",
			"eitthvað",
			"sem",
			"mér",
			"fannst",
			"ekki",
			"sexy",
			"heldur",
			"niðurlægjandi.",
		],
		professionals: [
			38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 67, 68,
			69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80,
		],
		explanation:
			"Við strikum undir þarna vegna þess að það að hann skuli vera að mana hann til kynferðislegra athafna er ekki í lagi. Það er leið til þvingunar og ljóst að hann er að reyna að fara yfir mörk og niðurlægja. \n Ef þú sérð sjálfa/sjálft/sjálfan þig í öðru hvoru af þessum hlutverkum er mikilvægt að leita aðstoðar. Hjá mörgum er fyrsta skrefið að tala við foreldri, kennara, starfsfólk félagsmiðstöðvar, þjálfara eða einhvern annan sem þú þekkir og treystir. Ef þér líst ekki á það getur þú t.d. haft samband við netspjall Stígamóta, vinasímann 1717, Bjarkarhlíð, lögreglu eða barnavernd ef þú ert yngri en 18 ára.",
	},

	{
		id: 5,
		story: [
			"Mér",
			"var",
			"nauðgað",
			"fyrir",
			"fjórum",
			"árum",
			"síðan.",
			"Ég",
			"er",
			"búin",
			"að",
			"vera",
			"hjá",
			"sálfræðingi",
			"og",
			"var",
			"farið",
			"að",
			"líður",
			"betur.",
			"Það",
			"er",
			"til",
			"myndband",
			"af",
			"því",
			"og",
			"stelpa",
			"sem",
			"ég",
			"þekki",
			"kom",
			"því",
			"í",
			"dreifingu",
			"nýlega.",
			"Það",
			"lét",
			"mér",
			"aftur",
			"líða",
			"nákvæmlega",
			"eins",
			"ömurlega",
			"og",
			"mér",
			"leið",
			"fyrst",
			"og",
			"núna",
			"er",
			"ég",
			"eiginlega",
			"bara",
			"búin",
			"að",
			"loka",
			"mig",
			"af.",
		],
		professionals: [
			0, 1, 2, 3, 4, 5, 6, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34,
			35,
		],
		explanation:
			"Við strikum fyrst yfir þegar hún talar um nauðgunina af því að þar er hún að tala um mjög alvarlegt ofbeldi sem hún var beitt. Síðan strikum við líka yfir þar sem hún talar um myndbandið og að einhver hafi dreift því. Þar er hún að segja frá því sem er kallað stafrænt kynferðisofbeldi sem er mjög alvarlegt og hefur miklar afleiðingar fyrir þolendur.\nEf þú hefur lent í stafrænu kynferðisofbeldi er mikilvægt að leita aðstoðar. Hjá mörgum er fyrsta skrefið að tala við foreldri, kennara, starfsfólk félagsmiðstöðvar, þjálfara eða einhvern annan sem þú þekkir og treystir. Ef þér líst ekki á það getur þú t.d. haft samband við netspjall Stígamóta, vinasímann 1717, Bjarkarhlíð, lögreglu eða barnavernd ef þú ert yngri en 18 ára.",
	},
	{
		id: 6,
		story: [
			"Ég",
			"er",
			"byrjuð",
			"í",
			"kynleiðréttingarferli",
			"og",
			"tek",
			"blokkera",
			"en",
			"ég",
			"er",
			"ennþá",
			"skikkuð",
			"í",
			"strákaklefann",
			"ef",
			"ég",
			"ætla",
			"að",
			"fara",
			"í",
			"sund",
			"eða",
			"íþróttir",
			"í",
			"skólanum.",
			"Núna",
			"skrópa",
			"ég",
			"alltaf",
			"en",
			"hvað",
			"get",
			"ég",
			"gert",
			"til",
			"að",
			"láta",
			"skólann",
			"minn",
			"skilja",
			"hver",
			"ég",
			"er?",
		],
		professionals: [
			9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
		],
		explanation:
			"Við strikum yfir þarna vegna þess að það að skólinn reyni að skylda hana í búningsklefa sem hún vill ekki fara í er ofbeldi. Skólinn er í valdastöðu gagnvart börnum og unglingum og þess vegna þarf hún að skrópa og væntanlega taka refsingunni sem því fylgir. Þetta er dæmi um kerfisbundið ofbeldi sem er t.d. þegar reglurnar gera ekki ráð fyrir öllu fólki og eru þess vegna mjög slæmar fyrir suma. Trans fólk er meðal þeirra hópa sem lendir oft í svona ofbeldi. \nÞað getur verið erfitt að átta sig á kerfisbundnu ofbeldi en ef þér finnst þú vera að lenda í óréttlæti eða ofbeldi ættirðu alltaf að láta vita og leita aðstoðar. Hjá mörgum er fyrsta skrefið að tala við foreldri, kennara, starfsfólk félagsmiðstöðvar, þjálfara eða einhvern annan sem þú þekkir og treystir. Ef þér líst ekki á það getur þú t.d. haft samband við netspjall Stígamóta, vinasímann 1717, Bjarkarhlíð, lögreglu eða barnavernd ef þú ert yngri en 18 ára.",
	},

	{
		id: 7,
		story: [
			"Hann",
			"vildi",
			"alltaf",
			"gera",
			"alls",
			"konar,",
			"stunda",
			"kynlíf",
			"eða",
			"fá",
			"nektarmyndir",
			"af",
			"mér.",
			"Ég",
			"var",
			"ekki",
			"tilbúin",
			"og",
			"sagði",
			"nei",
			"en",
			"þá",
			"suðaði",
			"hann",
			"í",
			"mér",
			"þangað",
			"til",
			"ég",
			"sagði",
			"já.",
			"Hann",
			"laug",
			"oft",
			"að",
			"mér",
			"og",
			"var",
			"að",
			"reyna",
			"við",
			"aðrar",
			"stelpur",
			"beint",
			"fyrir",
			"framan",
			"mig.",
			"Mér",
			"finnst",
			"ennþá",
			"erfitt",
			"að",
			"treysta",
			"öðru",
			"fólki",
			"út",
			"af",
			"honum.",
			"Svo",
			"eftir",
			"að",
			"ég",
			"hætti",
			"með",
			"honum",
			"þá",
			"hótaði",
			"hann",
			"mér",
			"og",
			"gerði",
			"mig",
			"rosalega",
			"hrædda",
			"og",
			"óörugga",
			"í",
			"kringum",
			"annað",
			"fólk.",
		],
		professionals: [
			20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36,
			37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 58, 59, 60, 61, 62, 63, 64,
			64, 65, 66, 67, 68,
		],
		explanation:
			"Við strikum yfir þarna vegna þess að þarna er kærasti hennar að reyna að ná fram þvinguðu samþykki. Þvingað samþykki er alls ekki samþykki.Það er alltaf mjög mikilvægt að virða þegar fólk segir nei. Þegar kemur að kynlífi er sérstaklega mikilvægt að þvinga aldrei fram samþykki.\nÞað sést vel í sögunni að þetta var ekki eina tilfellið þar sem hann beitti ofbeldi. Við strikum því yfir fleira. Að ljúga eða reyna að valda manneskjunni sem þú ert í sambandi með vanlíðan með því að reyna við annað fólk fyrir framan þau eru mjög óheilbrigð samskipti. Hótanir eru ofbeldi og veldur fólki mikilli hræðslu og óöryggi, alveg eins og stelpan í sögunni segir frá.\nEf þú sérð sjálfa/sjálft/sjálfan þig í öðru hvoru af þessum hlutverkum er mikilvægt að leita aðstoðar. Hjá mörgum er fyrsta skrefið að tala við foreldri, kennara, starfsfólk félagsmiðstöðvar, þjálfara eða einhvern annan sem þú þekkir og treystir. Ef þér líst ekki á það getur þú t.d. haft samband við netspjall Stígamóta, vinasímann 1717, Bjarkarhlíð, lögreglu eða barnavernd ef þú ert yngri en 18 ára.",
	},
	{
		id: 8,
		story: [
			"Ég",
			"er",
			"16",
			"ára",
			"og",
			"kærastan",
			"mín",
			"leyfir",
			"mér",
			"bara",
			"að",
			"tala",
			"við",
			"tvo",
			"vini",
			"sem",
			"henni",
			"finnst",
			"í",
			"lagi",
			"að",
			"ég",
			"tali",
			"við.",
			"Hún",
			"lætur",
			"mig",
			"alltaf",
			"segja",
			"sér",
			"nákvæmlega",
			"hvar",
			"ég",
			"er",
			"og",
			"ég",
			"má",
			"ekki",
			"senda",
			"neinum",
			"skilaboð",
			"án",
			"þess",
			"að",
			"hún",
			"sjái",
			"þau.",
			"Hún",
			"velur",
			"eiginlega",
			"alltaf",
			"föt",
			"á",
			"mig",
			"og",
			"ef",
			"ég",
			"er",
			"í",
			"einhverju",
			"sem",
			"hún",
			"fílar",
			"ekki",
			"þá",
			"setur",
			"hún",
			"út",
			"á",
			"það",
			"endalaust",
			"og",
			"það",
			"er",
			"eiginlega",
			"niðurlægjandi.",
			"Hún",
			"stýrir",
			"eiginlega",
			"bara",
			"öllu,",
			"hverju",
			"ég",
			"pósta",
			"á",
			"netið,",
			"hvaða",
			"tónlist",
			"við",
			"hlustum",
			"á,",
			"hvað",
			"við",
			"gerum",
			"og",
			"allt.",
		],
		professionals: [
			5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
			23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
			40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56,
			57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73,
			74, 75, 76, 77, 78, 79, 90, 91, 92, 93, 94, 95,
		],
		explanation:
			"Þarna strikum við yfir næstum því allan textann þar sem manneskjan sem skrifar lýsir því að kærastan ræður nánast öllu. Í sambandi þurfa báðar manneskjur að geta ráðið sér sjálfar. Ef önnur manneskjan ræður miklu meiru heldur en hin er valdaójafnvægi í sambandinu sem sú manneskja notar til að beita ofbeldi.\nAð tjá sig, hvort sem er í tali, póstum á netinu, hegðun eða með fatavali er mikilvægur partur af réttindum fólks. Við eigum öll rétt á einkalífi og þurfum ekki að segja kærustu eða neinum öðrum frá öllum samtölum sem við eigum við annað fólk. Að taka þetta frá annarri manneskju eins og lýst er í sögunni er alvarlegt andlegt ofbeldi. Ef þú sérð sjálfa/sjálft/sjálfan þig í öðru hvoru af þessum hlutverkum er mikilvægt að leita aðstoðar. Hjá mörgum er fyrsta skrefið að tala við foreldri, kennara, starfsfólk félagsmiðstöðvar, þjálfara eða einhvern annan sem þú þekkir og treystir. Ef þér líst ekki á það getur þú t.d. haft samband við netspjall Stígamóta, vinasímann 1717, Bjarkarhlíð, lögreglu eða barnavernd ef þú ert yngri en 18 ára.",
	},
	{
		id: 9,
		story: [
			"Ég",
			"var",
			"15",
			"og",
			"hán",
			"líka.",
			"Við",
			"vorum",
			"saman",
			"í",
			"tvö",
			"ár",
			"og",
			"þetta",
			"var",
			"alveg",
			"fullkomið...",
			"þegar",
			"annað",
			"fólk",
			"var",
			"með.",
			"Hán",
			"var",
			"vant",
			"að",
			"öskra",
			"og",
			"blóta",
			"á",
			"mig",
			"þegar",
			"ég",
			"gerði",
			"eitthvað",
			"vitlaust,",
			"eins",
			"og",
			"að",
			"hella",
			"óvart",
			"niður",
			"eða",
			"eitthvað.",
			"Ef",
			"ég",
			"talaði",
			"við",
			"hán",
			"um",
			"þetta",
			"fór",
			"hán",
			"bara",
			"að",
			"gráta",
			"og",
			"var",
			"alveg",
			"miður",
			"sín",
			"en",
			"ef",
			"ég",
			"talaði",
			"við",
			"einhvern",
			"annan",
			"var",
			"bara",
			"aftur",
			"öskrað",
			"og",
			"sagt",
			"að",
			"okkar",
			"mál",
			"kæmu",
			"engum",
			"öðrum",
			"við.",
		],
		professionals: [
			17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33,
			34, 35, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58,
			59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75,
			76, 77, 78, 79, 80,
		],
		explanation:
			"Við strikum yfir þarna af því að þarna er hán að hræða manneskjuna sem skrifar söguna. Hán gerir þetta ekki þegar annað fólk heyrir og er ekki til í að ræða betri samskipti, svo að þetta er ekki óviljandi heldur gert til að beita ofbeldi.",
	},
	{
		id: 10,
		story: [
			"Hann",
			"var",
			"allt",
			"sem",
			"ég",
			"hélt",
			"að",
			"ég",
			"vildi.",
			"Góður",
			"og",
			"skilningsríkur",
			"og",
			"gaf",
			"mér",
			"allt",
			"sem",
			"ég",
			"vildi.",
			"Hann",
			"kom",
			"líka",
			"fram",
			"við",
			"mig",
			"eins",
			"og",
			"ég",
			"væri",
			"fullorðin,",
			"sem",
			"var",
			"æði",
			"því",
			"fólk",
			"á",
			"hans",
			"aldri",
			"gerði",
			"það",
			"oftast",
			"ekki.",
			"En",
			"það",
			"byrjaði",
			"að",
			"breytast",
			"ótrúlega",
			"hratt.",
			"Hann",
			"neyddi",
			"mig",
			"til",
			"að",
			"gera",
			"hluti",
			"við",
			"sig,",
			"sagði",
			"mér",
			"í",
			"hvaða",
			"fötum",
			"ég",
			"átti",
			"að",
			"vera",
			"og",
			"með",
			"hverjum",
			"ég",
			"mátti",
			"vera.",
			"Ég",
			"mátti",
			"aldrei",
			"hitta",
			"vini",
			"mína.",
			"Hann",
			"neitaði",
			"að",
			"nota",
			"smokk.",
			"Hann",
			"öskraði",
			"í",
			"andlitið",
			"á",
			"mér",
			"og",
			"einn",
			"daginn",
			"ýtti",
			"hann",
			"mér.",
			"Hann",
			"sagði",
			"að",
			"það",
			"hefði",
			"verið",
			"bara",
			"í",
			"gamni",
			"en",
			"það",
			"var",
			"mjög",
			"vont.",
		],
		professionals: [
			33, 34, 35, 36, 37, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53,
			54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70,
			71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87,
			88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103,
			104, 105, 106, 107, 108, 109,
		],
		explanation:
			"Við strikum þarna yfir af því að það hljómar eins og hann sé töluvert eldri en hún. Svo beitir hann miklu ofbeldi. Líkamlegu eins og að hrinda, kynbundnu eins og að neita að nota smokk og andlegu eins og að stýra og ógna.",
	},
	{
		id: 11,
		story: [
			"Ég",
			"var",
			"í",
			"sambandi",
			"með",
			"strák",
			"sem",
			"sagði",
			"mér",
			"að",
			"enginn",
			"annar",
			"myndi",
			"elska",
			"mig.",
			"Hann",
			"kallaði",
			"mig",
			"negra",
			"þegar",
			"hann",
			"var",
			"fúll",
			"við",
			"mig.",
			"Hann",
			"var",
			"vanur",
			"að",
			"senda",
			"öðrum",
			"strákum",
			"skilaboð",
			"af",
			"facebook",
			"eða",
			"instagraminu",
			"mínu",
			"til",
			"að",
			"segja",
			"þeim",
			"að",
			"láta",
			"mig",
			"vera.",
			"Hann",
			"var",
			"alltaf",
			"að",
			"skoða",
			"skilaboðin",
			"mín",
			"og",
			"senda",
			"mér",
			"skilaboð",
			"um",
			"hvar",
			"ég",
			"væri.",
			"Vinir",
			"mínir",
			"sögðu",
			"mér",
			"að",
			"það",
			"væri",
			"í",
			"lagi",
			"að",
			"hann",
			"skoðaði",
			"skilaboðin",
			"mín",
			"af",
			"því",
			"að",
			"það",
			"þýddi",
			"að",
			"hann",
			"elskaði",
			"mig.",
			"Núna",
			"er",
			"ég",
			"aðeins",
			"eldri",
			"og",
			"sé",
			"alveg",
			"að",
			"þetta",
			"var",
			"ekki",
			"heilbrigt",
			"samband.",
			"Ég",
			"var",
			"hrædd",
			"við",
			"að",
			"fara",
			"frá",
			"honum",
			"og",
			"hafði",
			"ekki",
			"sjáfstraust",
			"til",
			"þess.",
		],
		professionals: [
			7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
			25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41,
			42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58,
			59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75,
			76, 77, 78, 79, 81, 82, 83,
		],
		explanation:
			"Við strikum þarna yfir af því að hann reynir að draga úr henni og niðurlægja með því að tala svona. Hann má líka alls ekki skoða eða senda skilaboð af hennar reikningum án leyfis, sama hvað vinirnir segja.",
	},
	{
		id: 12,
		story: [
			"Þegar",
			"ég",
			"var",
			"um",
			"12",
			"ára",
			"var",
			"strákur",
			"með",
			"mér",
			"í",
			"körfuboltanum",
			"sem",
			"var",
			"rosalega",
			"stelpulegur.",
			"Við",
			"vorum",
			"alltaf",
			"aðeins",
			"að",
			"stríða",
			"honum",
			"á",
			"því.",
			"Það",
			"byrjaði",
			"frekar",
			"saklaust,",
			"við",
			"kölluðum",
			"hann",
			"kannski",
			"stundum",
			"stelpu",
			"eða",
			"homma.",
			"En",
			"svo",
			"á",
			"endanum",
			"var",
			"hann",
			"eiginlega",
			"aldrei",
			"kallaður",
			"annað",
			"en",
			"homminn",
			"og",
			"einhverjir",
			"farnir",
			"að",
			"taka",
			"stundum",
			"fötin",
			"hans",
			"og",
			"alls",
			"konar.",
			"Hann",
			"entist",
			"ekkert",
			"lengi",
			"og",
			"hætti",
			"að",
			"æfa",
			"með",
			"okkur",
			"áður",
			"en",
			"tímabilið",
			"kláraðist.",
		],
		professionals: [
			14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
			31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47,
			48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
		],
		explanation:
			"Við strikum þarna yfir af því að þetta er einelti. Þarna er sérstaklega verið að taka hann fyrir af því að hann uppfyllir ekki einhverja staðalímynd sem þeir hafa um hvernig strákar eiga að vera, sem gerir þetta að kynferðiseinelti.",
	},
	{
		id: 13,
		story: [
			"Þegar",
			"ég",
			"var",
			"í",
			"10.",
			"bekk",
			"kom",
			"nýr",
			"strákur",
			"í",
			"bekkinn",
			"minn",
			"sem",
			"er",
			"trans.",
			"Fyrst",
			"var",
			"það",
			"bara",
			"ekkert",
			"mál",
			"og",
			"við",
			"tókum",
			"held",
			"ég",
			"ágætlega",
			"á",
			"móti",
			"honum.",
			"Svo",
			"var",
			"einn",
			"strákur",
			"í",
			"bekknum",
			"sem",
			"komst",
			"að",
			"því",
			"hvað",
			"hann",
			"hét",
			"áður.",
			"Þá",
			"byrjaði",
			"eitthvað",
			"djók",
			"um",
			"að",
			"vera",
			"alltaf",
			"að",
			"kalla",
			"hann",
			"það.",
			"Mér",
			"fannst",
			"þetta",
			"eitthvað",
			"svo",
			"saklaust",
			"og",
			"fyndið",
			"þá,",
			"en",
			"svo",
			"hætti",
			"hann",
			"bara",
			"í",
			"skólanum",
			"og",
			"núna",
			"er",
			"ég",
			"búin",
			"að",
			"vera",
			"með",
			"samviskubit",
			"í",
			"svona",
			"2",
			"ár.",
		],
		professionals: [
			44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60,
			61, 62, 63, 64,
		],
		explanation:
			"Við strikum þarna yfir af því að þau eru að uppnefna hann með nafni sem hann vill ekki vera kallaður, sem er einelti. Trans fólk lendir því miður mikið í því að fólk virðir ekki þeirra rétt til að vera þau sjálf.",
	},
	{
		id: 14,
		story: [
			"Ég",
			"er",
			"alltaf",
			"að",
			"fá",
			"sendar",
			"typpamyndir",
			"án",
			"þess",
			"að",
			"biðja",
			"um",
			"það,",
			"kannski",
			"ætti",
			"þetta",
			"ekki",
			"að",
			"hafa",
			"svona",
			"mikil",
			"áhrif",
			"á",
			"mig",
			"en",
			"mér",
			"líður",
			"bara",
			"alltaf",
			"ömurlega",
			"yfir",
			"því.",
			"Svo",
			"þegar",
			"ég",
			"tala",
			"við",
			"vinkonur",
			"mínar",
			"um",
			"það",
			"finnst",
			"mér",
			"eins",
			"og",
			"þær",
			"séu",
			"að",
			"kenna",
			"mér",
			"um",
			"það",
			"með",
			"því",
			"að",
			"segja",
			"að",
			"ég",
			"eigi",
			"bara",
			"ekki",
			"að",
			"adda",
			"svona",
			"mörgum",
			"á",
			"snap",
			"og",
			"insta.",
		],
		professionals: [
			2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 45, 46, 47, 48, 49, 50, 51, 52,
			53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68,
		],
		explanation:
			"Við strikum þarna yfir af því að hún er að lenda í kynferðislegri áreitni þegar hún fær sendar þessar myndir. Vinkonur hennar eru svo að setja ábyrgðina á hana sem er alls ekki í lagi og er kallað þolendaskömmun eða drusluskömmun.",
	},
	{
		id: 15,
		story: [
			"Strák",
			"í",
			"bekknum",
			"mínum",
			"er",
			"stundum",
			"strítt",
			"fyrir",
			"að",
			"vera",
			"stelpulegur.",
			"Það",
			"er",
			"einn",
			"úr",
			"bekknum",
			"sem",
			"gerir",
			"alltaf",
			"rosalega",
			"mikið,",
			"kallar",
			"hann",
			"stelpunöfnum",
			"og",
			"talar",
			"ógeðslega",
			"við",
			"hann.",
			"Stundum",
			"lætur",
			"hann",
			"líka",
			"eins",
			"og",
			"hann",
			"sé",
			"að",
			"reyna",
			"við",
			"hann,",
			"er",
			"að",
			"strjúka",
			"hann",
			"eða",
			"klípa",
			"í",
			"rassinn",
			"á",
			"honum",
			"og",
			"kallar",
			"hann",
			"sæta",
			"eða",
			"eitthvað",
			"svoleiðis.",
		],
		professionals: [
			6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
			24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
			41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57,
		],
		explanation:
			"Við strikum þarna yfir af því að þetta er kynferðisleg áreitni og einelti.  Þarna er hann tekinn fyrir því hann uppfyllir ekki einhverja staðalímynd um stráka en þær geta verið mjög skaðlegar.",
	},
	{
		id: 16,
		story: [
			"Ég",
			"sendi",
			"strák",
			"sem",
			"ég",
			"var",
			"að",
			"hitta",
			"mynd",
			"af",
			"mér",
			"næstum",
			"alveg",
			"nakinni.",
			"Svo",
			"þegar",
			"við",
			"vorum",
			"hætt",
			"saman",
			"fór",
			"ég",
			"að",
			"frétta",
			"af",
			"því",
			"að",
			"hann",
			"hefði",
			"sýnt",
			"strákunum",
			"sem",
			"hann",
			"er",
			"að",
			"æfa",
			"með",
			"myndina.",
		],
		professionals: [27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37],
		explanation:
			"Við strikum þarna yfir af því að það er aldrei í lagi að deila eða sýna öðrum nektarmyndir sem einhver hefur treyst þér fyrir. Það heitir stafrænt kynferðisofbeldi og veldur mikilli vanlíðan þolenda.",
	},
	{
		id: 17,
		story: [
			"Vinur",
			"minn",
			"er",
			"alltaf",
			"frekar",
			"mikill",
			"fáviti",
			"við",
			"stelpur",
			"þegar",
			"hann",
			"er",
			"búinn",
			"að",
			"drekka",
			"áfengi.",
			"Ég",
			"hef",
			"oft",
			"séð",
			"hann",
			"káfa",
			"á",
			"stelpum",
			"sem",
			"við",
			"hittum,",
			"bæði",
			"ókunnugum",
			"og",
			"líka",
			"stelpum",
			"sem",
			"við",
			"þekkjum.",
		],
		professionals: [
			2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
			21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34,
		],
		explanation:
			"Við strikum þarna yfir af því að þetta er kynferðisleg áreitni hjá vin hans. Ef hann ræður ekki við að haga sér almennilega þegar hann er að drekka áfengi ætti hann ekki að gera það. Áfengi er ekki afsökun.",
	},
	{
		id: 18,
		story: [
			"Einu",
			"sinni",
			"á",
			"balli",
			"í",
			"skólanum",
			"mínum",
			"voru",
			"tveir",
			"strákar",
			"sem",
			"lokuðu",
			"mig",
			"inni",
			"á",
			"klósetti",
			"og",
			"neituðu",
			"að",
			"hleypa",
			"mér",
			"út",
			"nema",
			"ég",
			"sýndi",
			"þeim",
			"á",
			"mér",
			"brjóstin.",
		],
		professionals: [
			8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
			25, 26, 27, 28,
		],
		explanation:
			"Við strikum þarna yfir af því að þetta er þvingun og frelsissvipting sem mjög alvarlegt ofbeldi.",
	},
	{
		id: 19,
		story: [
			"Ég",
			"og",
			"vinir",
			"mínir",
			"tölum",
			"frekar",
			"illa",
			"um",
			"stelpur.",
			"Það",
			"er",
			"bara",
			"djók",
			"oftast",
			"en",
			"við",
			"erum",
			"að",
			"kalla",
			"þær",
			"ílát,",
			"druslur",
			"og",
			"nokkru",
			"sinnum",
			"hefur",
			"myndum",
			"af",
			"nöktum",
			"stelpum",
			"verið",
			"dreift",
			"á",
			"milli",
			"okkar.",
			"Við",
			"byrjuðum",
			"á",
			"þessu",
			"fyrir",
			"löngu",
			"og",
			"þó",
			"ég",
			"sé",
			"búinn",
			"að",
			"fatta",
			"að",
			"þetta",
			"er",
			"glötuð",
			"hegðun",
			"þá",
			"finnst",
			"mér",
			"erfitt",
			"að",
			"hætta",
			"því.",
			"Ég",
			"er",
			"kannski",
			"ekki",
			"að",
			"taka",
			"mjög",
			"mikið",
			"þátt",
			"lengur",
			"en",
			"ég",
			"segi",
			"samt",
			"aldrei",
			"neitt",
			"á",
			"móti",
			"þessu.",
		],
		professionals: [
			4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
			22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34,
		],
		explanation:
			"Við strikum þarna yfir af því að svona tal, alveg sama þó það sé djók, sýnir mikla vanvirðingu fyrir stelpunum. Svo fara þeir líka langt yfir strikið þegar þeir deila nektarmyndum.",
	},
	{
		id: 20,
		story: [
			"Ég",
			"var",
			"14",
			"ára",
			"og",
			"hann",
			"16.",
			"Ég",
			"átti",
			"aðra",
			"vini",
			"utan",
			"við",
			"sambandið",
			"en",
			"í",
			"hvert",
			"skipti",
			"sem",
			"ég",
			"hékk",
			"eitthvað",
			"með",
			"þeim",
			"varð",
			"hann",
			"reiður",
			"og",
			"sagði",
			"kannski",
			",,þú",
			"elskar",
			"mig,",
			"er",
			"það",
			"ekki”",
			"til",
			"að",
			"ég",
			"myndi",
			"bara",
			"vera",
			"með",
			"honum.",
			"Hann",
			"var",
			"alltaf",
			"að",
			"biðja",
			"mig",
			"um",
			"eitthvað",
			"tengt",
			"kynlífi,",
			"og",
			"stundum",
			"var",
			"ég",
			"kannski",
			"bara",
			"ekki",
			"í",
			"stuði",
			"eða",
			"fannst",
			"það",
			"sem",
			"hann",
			"var",
			"að",
			"biðja",
			"um",
			"vera",
			"óþægilegt.",
			"Þá",
			"hunsaði",
			"hann",
			"mig",
			"bara",
			"inni",
			"í",
			"herberginu",
			"hans",
			"í",
			"marga",
			"klukkutíma.",
			"Svo",
			"sátum",
			"við",
			"bara",
			"í",
			"þögn",
			"þangað",
			"til",
			"ég",
			"gafst",
			"upp.",
		],
		professionals: [
			14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
			31, 32, 33, 34, 35, 36, 37, 38, 39,
		],
		explanation:
			"Við strikum yfir þarna af því að þarna er kærastinn að stýra því hvað hún gerir. Svona fýlustjórnun til að einangra eða þvinga fram kynlíf er ofbeldi.",
	},
	{
		id: 21,
		story: [
			"Hann",
			"vildi",
			"alltaf",
			"gera",
			"alls",
			"konar,",
			"stunda",
			"kynlíf",
			"eða",
			"fá",
			"nektarmyndir",
			"af",
			"mér.",
			"Ég",
			"var",
			"ekki",
			"tilbúin",
			"og",
			"sagði",
			"nei",
			"en",
			"þá",
			"suðaði",
			"hann",
			"í",
			"mér",
			"þangað",
			"til",
			"ég",
			"sagði",
			"já.",
			"Svo",
			"eftir",
			"að",
			"ég",
			"hætti",
			"með",
			"honum",
			"þá",
			"hótaði",
			"hann",
			"mér",
			"og",
			"gerði",
			"mig",
			"rosalega",
			"hrædda",
			"og",
			"óörugga",
			"í",
			"kringum",
			"annað",
			"fólk.",
		],
		professionals: [
			21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
			38,
		],
		explanation:
			"Við strikum yfir þarna af því að þarna er kærasti hennar að þvinga fram samþykki og þvingað samþykki er alls ekki samþykki. Seinna hótar hann henni sem er ofbeldi og veldur fólki mikilli hræðslu og óöryggi.",
	},
	{
		id: 22,
		story: [
			"Í",
			"10.",
			"bekk",
			"með",
			"mér",
			"var",
			"ein",
			"stelpa",
			"sem",
			"gengu",
			"alltaf",
			"sögur",
			"um,",
			"þ.e.",
			"að",
			"hún",
			"væri",
			"búin",
			"að",
			"sofa",
			"hjá",
			"mörgum,",
			"væri",
			"til",
			"í",
			"að",
			"senda",
			"nektarmyndir",
			"af",
			"sér",
			"á",
			"hvern",
			"sem",
			"er",
			"og",
			"ýmislegt",
			"í",
			"þeim",
			"dúr.",
			"Ég",
			"veit",
			"að",
			"einhverjir",
			"strákar",
			"í",
			"árgangnum",
			"voru",
			"að",
			"spyrja",
			"hana",
			"og",
			"líka",
			"oft",
			"að",
			"klípa",
			"í",
			"brjóstin",
			"á",
			"henni",
			"eða",
			"eitthvað",
			"svoleiðis.",
			"Við",
			"stelpurnar",
			"vorum",
			"ekki",
			"mikið",
			"með",
			"henni",
			"en",
			"reyndum",
			"einu",
			"sinni",
			"að",
			"gefa",
			"henni",
			"ráð",
			"um",
			"hvað",
			"hún",
			"gæti",
			"gert",
			"til",
			"að",
			"allir",
			"héldu",
			"ekki",
			"að",
			"hún",
			"væri",
			"drusla.",
			"Við",
			"meintum",
			"það",
			"í",
			"rauninni",
			"vel",
			"en",
			"ég",
			"veit",
			"núna",
			"að",
			"það",
			"lét",
			"henni",
			"kannski",
			"bara",
			"líða",
			"verr.",
		],
		professionals: [
			8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
			25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41,
			42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58,
			59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75,
			76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90,
		],
		explanation:
			"Við strikum þarna yfir af því að þetta er einelti. Þessi tegund er kölluð kynferðiseinelti og hefur mikil og slæm áhrif á þau sem lenda í því",
	},
	{
		id: 23,
		story: [
			"Kærastinn",
			"minn",
			"er",
			"frekar",
			"stjórnsamur",
			"en",
			"það",
			"truflar",
			"mig",
			"samt",
			"ekkert",
			"mikið",
			"því",
			"það",
			"er",
			"bara",
			"af",
			"því",
			"að",
			"hann",
			"elskar",
			"mig",
			"alveg",
			"eins",
			"og",
			"ég",
			"er.",
			"Hann",
			"er",
			"ótrúlega",
			"duglegur",
			"að",
			"segja",
			"mér",
			"að",
			"ég",
			"sé",
			"svo",
			"sætur",
			"og",
			"flottur",
			"og",
			"er",
			"alltaf",
			"að",
			"hrósa",
			"mér.",
			"Hann",
			"vill",
			"ekki",
			"að",
			"ég",
			"breytist,",
			"fari",
			"að",
			"mála",
			"mig",
			"eða",
			"lita",
			"á",
			"mér",
			"hárið.",
			"Ég",
			"myndi",
			"alveg",
			"gera",
			"það",
			"ef",
			"hann",
			"myndi",
			"leyfa",
			"en",
			"ég",
			"sleppi",
			"því",
			"bara",
			"til",
			"að",
			"við",
			"getum",
			"verið",
			"hamingjusamir",
			"saman.",
		],
		professionals: [
			0, 1, 2, 3, 4, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
			60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76,
			77, 78, 79, 81, 82,
		],
		explanation:
			"Við strikum yfir þarna af því að stjórnsemi í sambandi getur verið rautt flagg. Þegar hann heldur áfram með söguna kemur líka í ljós að stjórnsemi kærastans er of mikil.",
	},
	{
		id: 24,
		story: [
			"Ég",
			"var",
			"að",
			"hitta",
			"stelpu",
			"og",
			"við",
			"töluðum",
			"oft",
			"saman",
			"á",
			"Facetime.",
			"Einu",
			"sinni",
			"sofnaði",
			"hún",
			"og",
			"ég",
			"tók",
			"nokkar",
			"myndir",
			"af",
			"henni",
			"á",
			"meðan.",
			"Ég",
			"fatta",
			"ekki",
			"alveg",
			"hvað",
			"var",
			"svona",
			"rangt",
			"við",
			"það",
			"en",
			"henni",
			"fannst",
			"það",
			"svo",
			"óþægilegt",
			"að",
			"hún",
			"hætti",
			"með",
			"mér.",
			"Við",
			"erum",
			"saman",
			"í",
			"skóla",
			"og",
			"þegar",
			"ég",
			"hitti",
			"hana",
			"á",
			"göngunum",
			"reyndi",
			"ég",
			"að",
			"fá",
			"hana",
			"til",
			"að",
			"halda",
			"áfram",
			"að",
			"vera",
			"með",
			"mér,",
			"bara",
			"með",
			"að",
			"segja",
			"henni",
			"hvað",
			"hún",
			"er",
			"sæt",
			"eða",
			"kyssa",
			"hana",
			"á",
			"kinnina",
			"óvænt",
			"og",
			"eitthvað.",
			"En",
			"svo",
			"var",
			"kennari",
			"sem",
			"talaði",
			"við",
			"mig",
			"og",
			"bannaði",
			"það.",
			"Þá",
			"hafði",
			"hún",
			"klagað",
			"mig.",
			"Ég",
			"skil",
			"núna",
			"að",
			"ég",
			"var",
			"of",
			"ágengur",
			"og",
			"að",
			"hún",
			"var",
			"búin",
			"að",
			"biðja",
			"mig",
			"að",
			"hætta.",
		],
		professionals: [
			52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68,
			69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85,
			86, 87,
		],
		explanation:
			"Við strikum þarna yfir af því að hann hefur ekki fengið leyfi til að taka myndirnar og hunsar svo þau mörk sem hún setur. Það er gott að hann er að byrja að skilja að hann þarf að breyta sinni hegðun og taka ábyrgð.",
	},
	{
		id: 25,
		story: [
			"Ég",
			"og",
			"kærasti",
			"minn",
			"vorum",
			"í",
			"góðu",
			"sambandi",
			"þangað",
			"til",
			"hann",
			"langaði",
			"að",
			"byrja",
			"að",
			"stunda",
			"kynlíf",
			"en",
			"ég",
			"var",
			"ekki",
			"tilbúin",
			"í",
			"það.",
			"Ég",
			"sagði",
			"honum",
			"að",
			"ég",
			"væri",
			"til",
			"í",
			"það",
			"af",
			"því",
			"að",
			"ég",
			"var",
			"hrædd",
			"um",
			"að",
			"hann",
			"færi",
			"frá",
			"mér",
			"ef",
			"ég",
			"vildi",
			"það",
			"ekki.",
			"Þegar",
			"við",
			"vorum",
			"að",
			"fara",
			"að",
			"gera",
			"það",
			"þá",
			"stoppaði",
			"ég",
			"hann",
			"og",
			"sagði",
			"að",
			"mér",
			"þætti",
			"þetta",
			"óþægilegt.",
			"Hann",
			"hélt",
			"samt",
			"áfram",
			"að",
			"reyna",
			"þangað",
			"til",
			"ég",
			"ýtti",
			"honum",
			"af",
			"mér.",
			"Þá",
			"sagði",
			"hann",
			"að",
			"hann",
			"elskaði",
			"mig",
			"ekki",
			"lengur",
			"og",
			"langaði",
			"ekki",
			"að",
			"hitta",
			"mig",
			"áfram.",
			"Hann",
			"hafði",
			"líka",
			"áður",
			"orðið",
			"pirraður",
			"við",
			"mig",
			"yfir",
			"að",
			"vilja",
			"ekki",
			"senda",
			"nektarmyndir",
			"af",
			"mér.",
		],
		professionals: [
			69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85,
			86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101,
			102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113,
		],
		explanation:
			"Við strikum yfir þarna af því að þegar hún segir stopp reynir hann að nauðga henni. Áður hafði hann líka reynt að nota fýlustjórnun til að ná sínu fram.",
	},
	{
		id: 26,
		story: [
			"Ég",
			"er",
			"16",
			"ára",
			"stelpa.",
			"Ég",
			"fattaði",
			"að",
			"ég",
			"væri",
			"ekki",
			"fyrir",
			"stráka",
			"á",
			"meðan",
			"ég",
			"var",
			"í",
			"sambandi",
			"með",
			"strák.",
			"Þegar",
			"ég",
			"reyndi",
			"að",
			"segja",
			"honum",
			"það",
			"sagði",
			"hann",
			"að",
			"það",
			"væri",
			"ekki",
			"rétt",
			"hjá",
			"mér,",
			"kannski",
			"væri",
			"ég",
			"alveg",
			"líka",
			"fyrir",
			"stelpur",
			"en",
			"að",
			"það",
			"gæti",
			"ekki",
			"annað",
			"verið",
			"en",
			"að",
			"ég",
			"væri",
			"fyrri",
			"stráka",
			"því",
			"við",
			"hefðum",
			"gert",
			"„alls",
			"konar“",
			"saman.",
			"Ég",
			"varð",
			"hrædd",
			"við",
			"hann",
			"afþví",
			"hann",
			"var",
			"eldri",
			"og",
			"stærri",
			"en",
			"ég",
			"og",
			"ég",
			"var",
			"hrædd",
			"um",
			"að",
			"hann",
			"myndi",
			"kannski",
			"meiða",
			"mig",
			"ef",
			"ég",
			"reyndi",
			"að",
			"fara",
			"frá",
			"honum,",
			"líka",
			"út",
			"af",
			"öðru",
			"sem",
			"hann",
			"hafði",
			"gert",
			"áður",
			"(grípa",
			"í",
			"mig,",
			"toga",
			"harkalega",
			"í",
			"mig",
			"og",
			"meira",
			"að",
			"segja",
			"slá",
			"mig",
			"nokkrum",
			"sinnum).",
			"Það",
			"tók",
			"mig",
			"mjög",
			"langan",
			"tíma",
			"að",
			"hætta",
			"með",
			"honum,",
			"við",
			"vorum",
			"saman",
			"í",
			"næstum",
			"ár",
			"bara",
			"út",
			"af",
			"hræðslu.",
		],
		professionals: [
			28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44,
			45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61,
			62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78,
			79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95,
			96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109,
			110, 111, 112, 113, 114, 115, 116, 117,
		],
		explanation:
			"Við strikum yfir þarna af því að hann þykist vita betur um hver hún er. Seinna kemur svo í ljós að hann hefur líka ógnað og beitt líkamlegu ofbeldi, það er líka ofbeldi sem fer yfir strikið.",
	},
	{
		id: 27,
		story: [
			"Ég",
			"var",
			"rosalega",
			"skotinn",
			"í",
			"honum,",
			"en",
			"vissi",
			"að",
			"honum",
			"leið",
			"ekki",
			"alveg",
			"eins.",
			"Ég",
			"gerði",
			"mitt",
			"besta",
			"til",
			"að",
			"vera",
			"fullkominn",
			"fyrir",
			"hann.",
			"Hann",
			"nennti",
			"bara",
			"að",
			"hitta",
			"mig",
			"til",
			"að",
			"stunda",
			"kynlíf",
			"og",
			"var",
			"stundum",
			"harkalegur",
			"og",
			"lét",
			"mig",
			"gera",
			"hluti.",
			"Ég",
			"hélt",
			"að",
			"ef",
			"ég",
			"gerði",
			"eins",
			"og",
			"hann",
			"segði",
			"jafnvel",
			"þótt",
			"ég",
			"væri",
			"smá",
			"hrædd",
			"við",
			"hann",
			"þá",
			"myndi",
			"hann",
			"verða",
			"skotinn",
			"í",
			"mér",
			"líka.",
			"Núna",
			"er",
			"ég",
			"ekki",
			"búinn",
			"að",
			"hitta",
			"hann",
			"í",
			"7",
			"mánuði",
			"en",
			"hann",
			"hafði",
			"samt",
			"rosalega",
			"mikil",
			"áhrif",
			"á",
			"hvernig",
			"ég",
			"hugsa.",
			"En",
			"núna",
			"er",
			"ég",
			"í",
			"góðu",
			"sambandi",
			"og",
			"skil",
			"meira",
			"um",
			"ást",
			"og",
			"umhyggju.",
			"Þú",
			"ættir",
			"ekki",
			"að",
			"upplifa",
			"að",
			"vera",
			"þvinguð",
			"eða",
			"þurfa",
			"að",
			"gera",
			"eitthvað",
			"til",
			"að",
			"hann",
			"verði",
			"skotinn",
			"í",
			"þér.",
		],
		professionals: [
			24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
			41, 42,
		],
		explanation:
			"Það má alveg vera í kynferðislegu sambandi án tilfinninga. Við strikum samt yfir þarna af því að hún er skotin og hann ekki, hann virðist nota sér þær aðstæður og er harkalegur við hana og þvingar hana til að gera hluti sem hún vill ekki.",
	},
	{
		id: 28,
		story: [
			"Ég",
			"fæ",
			"mig",
			"ekki",
			"til",
			"að",
			"treysta",
			"kærastanum",
			"mínum.",
			"Ég",
			"held",
			"að",
			"hann",
			"hafi",
			"aldrei",
			"haldið",
			"framhjá",
			"eða",
			"neitt",
			"en",
			"ég",
			"bara",
			"losna",
			"ekki",
			"við",
			"þessa",
			"tilfinningu.",
			"Svo",
			"þess",
			"vegna",
			"er",
			"ég",
			"oft",
			"að",
			"stelast",
			"til",
			"að",
			"skoða",
			"símann",
			"hans,",
			"koma",
			"heim",
			"til",
			"hans",
			"þegar",
			"hann",
			"á",
			"ekki",
			"von",
			"á",
			"mér",
			"og",
			"alls",
			"konar",
			"annað",
			"til",
			"að",
			"reyna",
			"að",
			'"busta"',
			"hann.",
			"Ég",
			"er",
			"alveg",
			"svona",
			"týpa",
			"sem",
			"pælir",
			"í",
			"hvað",
			"er",
			"ofbeldi",
			"og",
			"svona",
			"svo",
			"ég",
			"veit",
			"alveg",
			"að",
			"það",
			"er",
			"ekki",
			"beint",
			"heilbrigt",
			"samband.",
		],
		professionals: [27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39],
		explanation:
			"Við strikum yfir þarna af því að hún er að fylgjast með honum á hátt sem er ekki í lagi. Allt fólk á rétt á einkalífi og að skoða síma fólks í leyfisleysi er ekki í lagi.",
	},
	{
		id: 29,
		story: [
			"Fyrverandi",
			"blokkaði",
			"mig",
			"á",
			"öllum",
			"miðlum",
			"svo",
			"nú",
			"þarf",
			"ég",
			"alltaf",
			"að",
			"nota",
			"nýtt",
			"account",
			"eða",
			"senda",
			"frá",
			"vinum",
			"mínum",
			"til",
			"að",
			"senda",
			"skilaboð.",
		],
		professionals: [
			6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
		],
		explanation:
			"Við strikum yfir þarna af því að fyrrverandi hefur sett skýr mörk um að vilja ekki samskipti. Það er alls ekki í lagi að hunsa þau mörk og getur gert hina manneskjuna hrædda og óörugga.",
	},
	{
		id: 30,
		story: [
			"Ég",
			"fékk",
			"einu",
			"sinni",
			"far",
			"heim",
			"úr",
			"partýi.",
			"Bílstjórinn",
			"fór",
			"að",
			"djóka",
			"með",
			"hvernig",
			"ég",
			"ætlaði",
			"að",
			"borga",
			"fyrir",
			"það.",
			"Hann",
			"skutlaði",
			"vinkonu",
			"minni",
			"heim",
			"fyrst",
			"og",
			"svo",
			"vini",
			"sínum.",
			"Þegar",
			"við",
			"vorum",
			"orðin",
			"bara",
			"tvö",
			"setti",
			"hann",
			"hendina",
			"á",
			"lærið",
			"á",
			"mér.",
			"Ég",
			"ýtti",
			"henni",
			"í",
			"burtu",
			"og",
			"sagðist",
			"ekki",
			"vilja",
			"þetta.",
			"Hann",
			"hló",
			"bara",
			"og",
			"lét",
			"eins",
			"og",
			"það",
			"væri",
			"allt",
			"í",
			"lagi.",
			"En",
			"svo",
			"fór",
			"hann",
			"að",
			"keyra",
			"alltaf",
			"hraðar",
			"og",
			"hraðar,",
			"hann",
			"fór",
			"yfir",
			"á",
			"rauðu",
			"ljósi",
			"og",
			"svaraði",
			"mér",
			"ekkert",
			"þegar",
			"ég",
			"bað",
			"hann",
			"um",
			"að",
			"hægja",
			"á.",
			"Hann",
			"var",
			"að",
			"þessu",
			"alla",
			"leiðina",
			"heim",
			"og",
			"þegar",
			"hann",
			"stoppaði",
			"brosti",
			"hann",
			"til",
			"mín",
			"og",
			"spurði",
			"hvort",
			"ég",
			"hefði",
			"verið",
			"hrædd.",
			"Ég",
			"sagði",
			"ekkert",
			"en",
			"ég",
			"var",
			"ógeðslega",
			"hrædd",
			"og",
			"hélt",
			"hann",
			"myndi",
			"gera",
			"mér",
			"eitthvað.",
		],
		professionals: [
			8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 65, 66, 67, 68, 69,
			70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86,
			87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102,
			103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114,
		],
		explanation:
			"Við strikum yfir þarna af því að djókið gæti verið rautt flagg. Hann er ógnandi við hana og misnotar það vald sem hann hefur sem bílstjóri til að hræða hana og stofna henni í hættu.",
	},
	{
		id: 31,
		story: [
			"Ég",
			"spurði",
			"vinkonu",
			"mína",
			"hvort",
			"það",
			"væri",
			"allt",
			"í",
			"lagi",
			"hjá",
			"henni",
			"í",
			"sambandinu",
			"hennar.",
			"Hún",
			"sagði",
			"að",
			"það",
			"væri",
			"allt",
			"í",
			"lagi",
			"en",
			"líkamstjáningin",
			"hennar",
			"virkaði",
			"eins",
			"og",
			"það",
			"væri",
			"ekki",
			"allt",
			"í",
			"lagi.",
			"Hún",
			"sagði",
			"mér",
			"að",
			"kærastinn",
			"hennar",
			"vildi",
			"ekki",
			"að",
			"hún",
			"væri",
			"að",
			"tala",
			"um",
			"sambandið",
			"þeirra",
			"við",
			"neinn",
			"annan,",
			"af",
			"því",
			"það",
			"gerði",
			"hann",
			"óöruggan.",
			"Henni",
			"fannst",
			"hún",
			"ekki",
			"geta",
			"talað",
			"við",
			"vini",
			"sína",
			"um",
			"hvernig",
			"henni",
			"leið.",
		],
		professionals: [
			24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
			41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57,
			58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72,
		],
		explanation:
			"Við strikum þarna yfir af því að líkamstjáning getur sagt okkur mikið um hvernig manneskju líður og af því að hún sagði að kærastinn vildi ekki að hún talaði um sambandið.",
	},
	{
		id: 32,
		story: [
			"Kærasti",
			"vinkonu",
			"minnar",
			"var",
			"alltaf",
			"pínu",
			"ágengur,",
			"kannski",
			"að",
			"setja",
			"hendina",
			"utan",
			"um",
			"axlirnar",
			"á",
			"henni",
			"og",
			"halda",
			"henni",
			"og",
			"svona",
			"þegar",
			"þau",
			"voru",
			"í",
			"kringum",
			"annað",
			"fólk.",
			"Það",
			"var",
			"svolítið",
			"mikið.",
			"Það",
			"leit",
			"út",
			"eins",
			"og",
			"henni",
			"þætti",
			"það",
			"alveg",
			"smá",
			"óþægilegt.",
			"Virkaði",
			"eins",
			"og",
			"hann",
			"væri",
			"að",
			"láta",
			"vita",
			"að",
			"hann",
			"ætti",
			"hana.",
		],
		professionals: [
			3, 4, 5, 6, 16, 17, 18, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
			39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54,
		],
		explanation:
			"Við strikum þarna yfir af því að hann er ágengur og að halda henni fastri. Manneskjan sem skrifar söguna er farin að hafa óþægilega tilfinningu fyrir þessu og það er mikilvægt að hlusta á þá tilfinningu.",
	},
	{
		id: 33,
		story: [
			"Vinkona",
			"mín",
			"segir",
			"að",
			"kærasti",
			"hennar",
			"sé",
			"frekar",
			"harkalegur",
			"við",
			"hana.",
			"Svona",
			"að",
			"toga",
			"í",
			"hana",
			"eða",
			"ýta",
			"henni,",
			"ekki",
			"beint",
			"að",
			"lemja",
			"hana",
			"samt.",
			"Grípur",
			"kannski",
			"í",
			"fötin",
			"hennar",
			"t.d.",
			"og",
			"öskrar",
			"í",
			"andlitið",
			"á",
			"henni",
			"þegar",
			"enginn",
			"er",
			"nálægt",
			"þeim.",
			"Hann",
			"er",
			"alltaf",
			"eitthvað",
			"að",
			"segja",
			"sem",
			"lætur",
			"henni",
			"líða",
			"illa",
			"eða",
			"eins",
			"og",
			"hún",
			"sé",
			"ekki",
			"nógu",
			"góð.",
			"Það",
			"er",
			"samt",
			"alveg",
			"pínu",
			"erfitt",
			"að",
			"trúa",
			"því",
			"af",
			"því",
			"að",
			"hann",
			"er",
			"alltaf",
			"sjúklega",
			"næs",
			"þegar",
			"ég",
			"sé",
			"þau",
			"og",
			"að",
			"leiða",
			"hana",
			"og",
			"svona",
			"bara",
			"extra",
			"sætur",
			"kærasti.",
			"Aðrar",
			"stelpur",
			"eru",
			"oft",
			"að",
			"segja",
			"hvað",
			"hann",
			"sé",
			"æðislegur",
			"kærasti.",
		],
		professionals: [
			0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
			19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35,
			36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52,
			53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69,
		],
		explanation:
			"Við strikum þarna yfir af því að hún er að láta vita að kærastinn beiti ofbeldi. Þá er rosalega mikilvægt að hlusta alltaf. Fólk sem beitir ofbeldi getur verið alls konar, jafnvel rosalega næs í kringum þig.",
	},
	{
		id: 34,
		story: [
			"Mér",
			"fór",
			"að",
			"finnast",
			"ég",
			"föst",
			"í",
			"sambandinu.",
			"Við",
			"hættum",
			"saman.",
			"Hann",
			"hélt",
			"áfram",
			"að",
			"hafa",
			"samband",
			"við",
			"mig",
			"í",
			"miklu",
			"uppnámi",
			"og",
			"við",
			"enduðum",
			"á",
			"að",
			"byrja",
			"saman",
			"aftur.",
			"Ég",
			"varð",
			"hálf",
			"hrædd",
			"við",
			"að",
			"segja",
			"hvernig",
			"mér",
			"leið",
			"því",
			"hann",
			"varð",
			"alltaf",
			"svo",
			"leiður",
			"yfir",
			"því.",
			"Svo",
			"var",
			"hann",
			"orðinn",
			"alveg",
			"viss",
			"um",
			"að",
			"ég",
			"myndi",
			"halda",
			"framhjá",
			"honum",
			"þannig",
			"að",
			"hann",
			"trackaði",
			"símann",
			"minn",
			"og",
			"varð",
			"reiður",
			"ef",
			"ég",
			"svaraði",
			"ekki",
			"skilaboðum",
			"frá",
			"honum",
			"eða",
			"var",
			"með",
			"öðru",
			"fólki.",
		],
		professionals: [
			15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
			32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48,
			49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65,
			66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81,
		],
		explanation:
			"Við strikum þarna yfir af því að þó það sé auðvitað í lagi og skiljanlegt að hann verði leiður þá virkar eins og hann sé að nota ýktar tilfinningar til að stýra henni og sem afsökun til að fylgjast með henni.",
	},
	{
		id: 35,
		story: [
			"Ég",
			"var",
			"í",
			"long",
			"distance",
			"sambandi.",
			"Ég",
			"var",
			"15",
			"og",
			"hann",
			"17.",
			"Það",
			"var",
			"fínt",
			"þangað",
			"til",
			"hann",
			"fór",
			"að",
			"reyna",
			"að",
			"fá",
			"mig",
			"til",
			"að",
			"senda",
			"sér",
			"nektarmyndir,",
			"sagðist",
			"muna",
			"halda",
			"áfram",
			"að",
			"spurja",
			"þangað",
			"til",
			"hann",
			"fengi",
			"þær.",
			"Hann",
			"sagði",
			"að",
			"IRL",
			"myndi",
			"ég",
			"ekki",
			"geta",
			"stoppað",
			"hann",
			"í",
			"að",
			"fá",
			"það",
			"sem",
			"hann",
			"vildi.",
			"Ég",
			"hætti",
			"með",
			"honum",
			"og",
			"þá",
			"fór",
			"hann",
			"að",
			"láta",
			"mig",
			"fá",
			"samviskubit",
			"yfir",
			"því",
			"og",
			"reyna",
			"að",
			"fá",
			"alla",
			"vini",
			"mína",
			"til",
			"að",
			"hata",
			"mig",
			"og",
			"reyna",
			"að",
			"komast",
			"inn",
			"á",
			"snap",
			"og",
			"svona",
			"hjá",
			"mér.",
			"Sem",
			"betur",
			"fer",
			"er",
			"hann",
			"núna",
			"hættur",
			"að",
			"hafa",
			"samband",
			"við",
			"mig",
			"randomly.",
		],
		professionals: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45,
			46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62,
			63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79,
			80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96,
			97, 98, 99, 100, 101, 102, 103, 104, 105, 106,
		],
		explanation:
			"Við strikum þarna yfir af því að hann hlustar ekki á það sem hún vill þegar hún segir nei, heldur hótar henni. Hann heldur svo áfram að koma illa fram á marga vegu.",
	},
	{
		id: 36,
		story: [
			"Ég",
			"átti",
			"kærasta",
			"og",
			"við",
			"vorum",
			"vön",
			"að",
			"spurja",
			"hvort",
			"annað",
			"hvort",
			"hitt",
			"væri",
			"til",
			"áður",
			"en",
			"við",
			"fórum",
			"að",
			"kyssast",
			"eða",
			"e-ð",
			"meira.",
			"Eitt",
			"skipti",
			"spurði",
			"hann",
			"og",
			"ég",
			"sagði",
			"nei",
			"en",
			"hann",
			"hélt",
			"samt",
			"áfram.",
			"Hann",
			"sagði",
			"að",
			"ég",
			"hefði",
			"sagt",
			"já",
			"en",
			"ég",
			"veit",
			"að",
			"ég",
			"gerði",
			"það",
			"ekki.",
		],
		professionals: [
			32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48,
			49, 50, 51,
		],
		explanation:
			"Við strikum þarna yfir af því að hann hlustar ekki þegar hún segir nei. Það er líka rautt flagg að hann segi að hún hafi sagt já, í staðinn fyrir að segjast t.d. hafa misheyrst.",
	},
	{
		id: 37,
		story: [
			"Þegar",
			"ég",
			"var",
			"nýflutt",
			"til",
			"Íslands",
			"var",
			"mikið",
			"gert",
			"grín",
			"að",
			"mér,",
			"ég",
			"var",
			"kölluð",
			"grjón",
			"og",
			"sagt",
			"að",
			"ég",
			"væri",
			"að",
			"leita",
			"að",
			"sugar",
			"daddy",
			"af",
			"því",
			"að",
			"ég",
			"er",
			"asísk.",
			"Þess",
			"vegna",
			"var",
			"svo",
			"geggjað",
			"þegar",
			"hann",
			"byrjaði",
			"að",
			"hafa",
			"áhuga.",
			"Hann",
			"nennti",
			"að",
			"tala",
			"við",
			"mig",
			"þó",
			"ég",
			"væri",
			"ekki",
			"búin",
			"að",
			"læra",
			"mikla",
			"íslensku.",
			"Hann",
			"talaði",
			"mest",
			"við",
			"mig",
			"þegar",
			"við",
			"vorum",
			"ein",
			"og",
			"tók",
			"þátt",
			"í",
			"að",
			"gera",
			"grín",
			"að",
			"mér",
			"þegar",
			"fólk",
			"var",
			"nálægt.",
			"Hann",
			"var",
			"samt",
			"alveg",
			"stundum",
			"næs",
			"við",
			"mig",
			"þó",
			"við",
			"værum",
			"kringum",
			"fólk",
			"og",
			"ég",
			"alveg",
			"lifði",
			"fyrir",
			"þessi",
			"móment.",
			"Hann",
			"fór",
			"fljótt",
			"að",
			"biðja",
			"um",
			"alls",
			"konar",
			"kynferðislegt",
			"og",
			"ég",
			"meikaði",
			"ekki",
			"að",
			"segja",
			"nei",
			"því",
			"hann",
			"var",
			"sá",
			"eini",
			"sem",
			"ég",
			"hafði.",
		],
		professionals: [
			6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
			24, 25, 26, 27, 28, 29, 30, 31, 58, 59, 60, 61, 62, 63, 64, 65, 66,
			67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83,
			84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113,
			114, 115, 116, 117, 118, 119, 120, 121, 122, 123,
		],
		explanation:
			"Við strikum þarna yfir af því að það sem hún lýsir er einelti og hann tekur þátt í því. Hann nýtir sér einangrunina hennar til að áreita hana og þvinga hana til að gera eitthvað sem hún vill ekki.",
	},
	{
		id: 38,
		story: [
			"Ég",
			"byrjaði",
			"með",
			"stelpu",
			"í",
			"fyrra",
			"og",
			"sagði",
			"foreldrum",
			"mínum",
			"frá.",
			"Þeim",
			"fannst",
			"allt",
			"í",
			"lagi",
			"að",
			"ég",
			"væri",
			"lesbía,",
			"eða",
			"ég",
			"hélt",
			"það",
			"allavega.",
			"Svo",
			"um",
			"daginn",
			"átti",
			"amma",
			"mín",
			"afmæli",
			"og",
			"mamma",
			"mín",
			"sagði",
			"mér",
			"að",
			"taka",
			"kærustuna",
			"mína",
			"ekki",
			"með,",
			"en",
			"samt",
			"fór",
			"bróðir",
			"minn",
			"með",
			"kærustuna",
			"sína.",
			"Mamma",
			"sagði",
			"að",
			"amma",
			"væri",
			"af",
			"annarri",
			"kynslóð",
			"og",
			"allt",
			"svona",
			"hinsegin",
			"væri",
			"of",
			"flókið",
			"fyrir",
			"hana.",
			"Svo",
			"þegar",
			"allir",
			"í",
			"afmælinu",
			"voru",
			"að",
			"spurja",
			"hvað",
			"væri",
			"að",
			"frétta",
			"þurfti",
			"ég",
			"að",
			"þykjast",
			"eiga",
			"kærasta",
			"og",
			"eitthvað.",
		],
		professionals: [
			33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 51, 52, 53, 54, 55, 56, 57,
			58, 59, 61, 62, 63, 64, 65, 66, 67, 68, 69, 71, 72, 73, 74, 75, 76,
			77, 78, 79, 81, 82, 83, 84, 85, 86, 87,
		],
		explanation:
			"Við strikum þarna yfir af því að hinsegin fólk lendir allt of oft í því að þeirra nánustu standa ekki með þeim. Það getur stafað af fordómum eða vankunnáttu en er alls ekki í lagi.",
	},
	{
		id: 39,
		story: [
			"Síðasta",
			"sumar",
			"var",
			"ég",
			"í",
			"unglingavinnunni",
			"og",
			"kynntist",
			"öðrum",
			"strák",
			"sem",
			"var",
			"með",
			"mér",
			"í",
			"árgangi.",
			"Ég",
			"kom",
			"út",
			"úr",
			"skápnum",
			"í",
			"7.",
			"bekk",
			"og",
			"var",
			"eini",
			"homminn",
			"í",
			"skólanum",
			"og",
			"mikið",
			"strítt",
			"fyrir",
			"það.",
			"Ég",
			"og",
			"þessi",
			"strákur",
			"fórum",
			"svo",
			"að",
			"flörta",
			"og",
			"enduðum",
			"á",
			"að",
			"eiginlega",
			"byrja",
			"saman",
			"nema",
			"hann",
			"vildi",
			"ekki",
			"að",
			"neinn",
			"vissi",
			"og",
			"sagði",
			"að",
			"allir",
			"myndu",
			"þá",
			"stríða",
			"honum",
			"líka.",
			"En",
			"svo",
			"þegar",
			"skólinn",
			"byrjaði",
			"var",
			"hann",
			"alltaf",
			"með",
			"vinum",
			"sínum",
			"í",
			"að",
			"kalla",
			"mig",
			"fagga",
			"og",
			"eitt",
			"skiptið",
			"voru",
			"þeir",
			"í",
			"frímínútum",
			"að",
			"tala",
			"geðveikt",
			"hátt",
			"um",
			"að",
			"allir",
			"hommar",
			"fengu",
			"aids",
			"og",
			"hann",
			"hló",
			"bara",
			"með.",
		],
		professionals: [
			31, 32, 33, 34, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78,
			79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95,
			96, 97, 98, 99, 100, 101, 102, 103,
		],
		explanation:
			"Við strikum þarna yfir af því að hann er að lenda í kynferðiseinelti. Það getur verið sérstaklega sárt þegar einhver sem þú taldir að myndi standa með þér tekur þátt í því.",
	},
	{
		id: 40,
		story: [
			"Ég",
			"og",
			"kærastan",
			"mín",
			"erum",
			"búin",
			"að",
			"vera",
			"saman",
			"í",
			"nokkra",
			"mánuði",
			"og",
			"höfum",
			"talað",
			"um",
			"hvað",
			"okkur",
			"finnst",
			"í",
			"lagi",
			"og",
			"hvað",
			"ekki",
			"með",
			"að",
			"tala",
			"við",
			"aðra",
			"stráka",
			"og",
			"eitthvað",
			"svoleiðis.",
			"Svo",
			"síðustu",
			"helgi",
			"fékk",
			"ég",
			"vidjó",
			"af",
			"henni",
			"í",
			"partý",
			"í",
			"sleik",
			"við",
			"einhverja",
			"aðra",
			"stelpu.",
			"Ég",
			"leyfði",
			"henni",
			"að",
			"fara",
			"í",
			"þetta",
			"partý",
			"án",
			"mín",
			"af",
			"því",
			"að",
			"hún",
			"sagði",
			"að",
			"það",
			"yrðu",
			"bara",
			"stelpur",
			"en",
			"ég",
			"átti",
			"ekki",
			"von",
			"á",
			"þessu.",
			"Hún",
			"segir",
			"að",
			"ég",
			"sé",
			"að",
			"gera",
			"alltof",
			"mikið",
			"mál",
			"úr",
			"þessu",
			"og",
			"að",
			"stelpur",
			"geri",
			"oft",
			"eitthvað",
			"svona",
			"og",
			"það",
			"telst",
			"ekki",
			"með.",
		],
		professionals: [
			50, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91,
			92, 93, 94, 95, 96, 97, 98, 99,
		],
		explanation:
			"Við strikum þarna yfir af því að þau þurfa bæði að breyta hegðun sinni. Hann talar um að leyfa henni að fara í partý sem hann ætti auðvitað ekki að ráða og hún gerir lítið úr hans tilfinningum af framhjáhaldi.",
	},
	{
		id: 41,
		story: [
			"Ég",
			"var",
			"aldrei",
			"eitthvað",
			"vinsæl",
			"í",
			"skólanum",
			"fyrr",
			"en",
			"ég",
			"byrjaði",
			"með",
			"annarri",
			"stelpu",
			"úr",
			"öðrum",
			"skóla.",
			"Einhver",
			"sagði",
			"krökkunum",
			"í",
			"bekknum",
			"og",
			"allt",
			"í",
			"einu",
			"fóru",
			"strákarnir",
			"að",
			"sýna",
			"mér",
			"geðveikan",
			"áhuga.",
			"Fyrst",
			"fannst",
			"mér",
			"það",
			"bara",
			"gaman",
			"en",
			"núna",
			"er",
			"alltaf",
			"verið",
			"að",
			"spurja",
			"mig",
			"meira",
			"og",
			"meira",
			"óþægilegra",
			"spurninga",
			"eins",
			"og",
			"hvernig",
			"tvær",
			"stelpur",
			"geta",
			"riðið",
			"og",
			"stundum",
			"eru",
			"strákar",
			"að",
			"spurja",
			"hvort",
			"okkur",
			"vanti",
			"ekki",
			"alvöru",
			"typpi.",
			"Ég",
			"er",
			"núna",
			"geðveikt",
			"stressuð",
			"að",
			"fara",
			"í",
			"skólann",
			"og",
			"vildi",
			"óska",
			"að",
			"enginn",
			"vissi",
			"af",
			"kærustunni",
			"minni.",
		],
		professionals: [
			42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58,
			59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70,
		],
		explanation:
			"Við strikum þarna yfir af því að þetta er tegund af hinsegin fordómum og það er kynferðisleg áreitni að strákarnir séu stöðugt að spurja út í hennar kynlíf og bjóðast til að vera með í því.",
	},
	{
		id: 42,
		story: [
			"Ég",
			"og",
			"kærastinn",
			"minn",
			"erum",
			"búin",
			"að",
			"vera",
			"saman",
			"í",
			"3",
			"ár",
			"og",
			"byrjuðum",
			"að",
			"sofa",
			"saman",
			"fyrir",
			"svona",
			"ári.",
			"Ég",
			"varð",
			"óvart",
			"ólétt",
			"og",
			"sagði",
			"honum",
			"frá",
			"og",
			"hélt",
			"hann",
			"yrði",
			"geggjað",
			"glaður",
			"en",
			"hann",
			"sagði",
			"að",
			"ég",
			"þyrfti",
			"að",
			"fara",
			"í",
			"þungunarrof",
			"annars",
			"myndi",
			"hann",
			"hætta",
			"með",
			"mér.",
			"Pabbi",
			"hans",
			"er",
			"lögmaður",
			"og",
			"hann",
			"sagði",
			"að",
			"það",
			"væri",
			"hægt",
			"að",
			"gera",
			"eitthvað",
			"svo",
			"hann",
			"væri",
			"ekki",
			"skráður",
			"sem",
			"pabbi",
			"barnsins",
			"og",
			"ég",
			"væri",
			"þá",
			"bara",
			"ein",
			"að",
			"hugsa",
			"um",
			"það.",
			"Ég",
			"fór",
			"síðan",
			"í",
			"þungunarrof",
			"því",
			"ég",
			"var",
			"skíthrædd",
			"að",
			"vera",
			"ein",
			"mamma",
			"og",
			"hann",
			"lætur",
			"eins",
			"og",
			"ekkert",
			"hafi",
			"gerst",
			"en",
			"mér",
			"líður",
			"ógeðslega",
			"illa",
			"með",
			"þetta",
			"allt.",
		],
		professionals: [
			36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52,
			53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69,
			70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81,
		],
		explanation:
			"Við strikum þarna yfir af því að hann þvingar hana til að fara í þungunarrof bæði með því að ljúga og hóta. Það er grunnréttur hverrar manneskju að fá að ráða eigin líkama.",
	},
	{
		id: 43,
		story: [
			"Ég",
			"var",
			"í",
			"9.",
			"bekk",
			"og",
			"hann",
			"amk",
			"20",
			"ára.",
			"Hann",
			"kom",
			"að",
			"spila",
			"á",
			"balli",
			"í",
			"félagsmiðstöðinni",
			"og",
			"var",
			"geggjað",
			"vinsæll",
			"hjá",
			"öllum",
			"stelpunum.",
			"Ég",
			"hitti",
			"hann",
			"úti",
			"þegar",
			"ég",
			"var",
			"á",
			"leiðinni",
			"heim",
			"og",
			"við",
			"fórum",
			"að",
			"spjalla,",
			"hann",
			"var",
			"á",
			"fullu",
			"að",
			"flörta",
			"og",
			"spurði",
			"svo",
			"hvort",
			"ég",
			"vildi",
			"fara",
			"heim",
			"með",
			"honum.",
			"Við",
			"sváfum",
			"saman.",
			"Núna",
			"er",
			"ég",
			"sjálf",
			"orðin",
			"22",
			"ára",
			"og",
			"tilhugsunin",
			"um",
			"að",
			"sofa",
			"hjá",
			"15",
			"ára",
			"krakka",
			"er",
			"bara",
			"ógeð",
			"og",
			"ég",
			"er",
			"loksins",
			"búin",
			"að",
			"skila",
			"skömminni",
			"til",
			"hans.",
		],
		professionals: [
			6, 7, 8, 9, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53,
			54, 55, 56, 57, 58,
		],
		explanation:
			"Við strikum þarna yfir af því að hann er fullorðinn og hún barn, sem þýðir að það er mikið valdamisræmi á milli þeirra.",
	},
	// {id: 83,
	//   story: [],
	//   professionals: [],
	//   explanation: ""
	// }
];
/*
	  	var myString = 'saga';
		var MyArray = myString.split(' ');
	*/
