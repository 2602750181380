import React from "react";
import { Link } from "react-router-dom";
import { DarkBlueButton } from "./Buttons/DarkBlueButton.jsx";
import YfirStrikid from "../yfirstrikid.png";

function FrontPage() {
  return (
    <div className="container page-template">
      <h2 className="headline front-screen">Yfir strikið</h2>
      <img className="yfir-strikid-img" src={YfirStrikid} alt="YfirStrikid" />
      <p className="front-screen front-screen-text">
        Þessi síða er ætluð unglingum á aldrinum 13-19 ára
      </p>
      <Link to={"/tutorial"}>
        <DarkBlueButton className="button">Byrja</DarkBlueButton>
      </Link>
    </div>
  );
}

export default FrontPage;
