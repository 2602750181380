import React, { Component } from "react";
// import YfirStrikid from "../yfirstrikid.png";
import QRcode from "../assets/qr-code.png";
import Erasmus from "../assets/erasmus.jpeg";
import Reykjavik from "../assets/reykjavik.jpg";
import Icelandic from "../assets/icelandic-roots.jpeg";
import Sidmennt from "../assets/sidmennt.jpeg";

class DesktopCatcher extends Component {
  render() {
    return (
      <div>
        {/* <img className="yfir-strikid-img" src={YfirStrikid} alt="YfirStrikid" /> */}
        <h2 className="headline desktop-headline">Yfir strikið</h2>
        <p>
          Þessi síða er einungis aðgengileg í snjallsíma. <br></br>
          Vinsamlegast heimsóttu síðuna á www.yfirstrikid.is í snjalltækinu
          þínu.
        </p>
        <p>
          Þú getur líka notað myndavélina í símanum þínum til að opna síðuna með
          QR kóðanum hér fyrir neðan.
        </p>
        <img src={QRcode} alt="QRcode" id="qr-code" />
        <h3 className="styrktaradilar-titill">Kærar þakkir fyrir stuðninginn!</h3>
        <div className="styrktaradilar">
          <p className="styrktaradilar-nafn">Aðalheiður Alenu</p>
          <p className="styrktaradilar-nafn">Anna Jonsdottir</p>
          <p className="styrktaradilar-nafn">Björgvin Kristinsson</p>
          <p className="styrktaradilar-nafn">David Johnson</p>
          <p className="styrktaradilar-nafn">Einar Ármannsson</p>
          <p className="styrktaradilar-nafn">Hafdís Karlsdóttir</p>
          <p className="styrktaradilar-nafn">Krista Hermanson</p>
          <p className="styrktaradilar-nafn">Sigríður Jónsdóttir</p>
          <p className="styrktaradilar-nafn">Sunna Furstenau</p>
          <p className="styrktaradilar-nafn">Sunna Ólafsdóttir Wallevik</p>
          <p className="styrktaradilar-nafn">Valborg Sturludóttir</p>
          <p className="styrktaradilar-nafn">Valgerður Jóhannsdóttir</p>
        </div>
        <div className="styrktaradilar-merki">
          <img src={Erasmus} alt="Erasmus" id="erasmus" />
          <img src={Reykjavik} alt="Reykjavik" id="reykjavik" />
          <img src={Icelandic} alt="Icelandic" id="icelandic" />
          <img src={Sidmennt} alt="Sidmennt" id="sidmennt" />
        </div>
      </div>
    );
  }
}

export default DesktopCatcher;
