import React, { Component } from "react";
import { Link } from "react-router-dom";
import { StoryData } from "../data";
import { LightBlueButton } from "./Buttons/LightBlueButton.jsx";
import { ReloadButton } from "./Buttons/ReloadButton.jsx";
import $ from "jquery";

class Story extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Random -  index of a random story:
      random: Math.round(Math.random() * (StoryData.length - 1)),
      // Array of words that have been selected
      wordArr: [],
    };
  }

  handleTouchMove = (e) => {
    // 1. Detect what element is under touch
    var xPos = e.touches[0].pageX;
    var yPos = e.touches[0].pageY;
    var wordSpan = $(document.elementFromPoint(xPos, yPos));

    if (wordSpan.is("span")) {
      // 2. Change background for overlining effect
      wordSpan.addClass("marked-user");

      // 3. Add element id to wordArr, if it is not in it before
      if (!this.state.wordArr.includes(Number(wordSpan.attr("id")))) {
        this.setState({
          wordArr: [...this.state.wordArr, Number(wordSpan.attr("id"))],
        });
      }
    }
  };

  refreshPage() {
    window.location.reload(false);
  }

  clearLines = () => {
    this.setState({
      wordArr: [],
    });
    $(".marked-user").removeClass("marked-user");
  };

  render() {
    return (
      <div className="story-template">
        <div className="story-title">
          <h2 className="story-title">
            Saga #<span id="number-of-story"> {this.state.random}</span>
          </h2>
          <ReloadButton className="reload-button" onClick={this.refreshPage}>
            &#x21bb;
          </ReloadButton>
        </div>
        <p className="clear-marks" onClick={this.clearLines}>
          Hreinsa
        </p>

        <div className="no-overflow story-text" id="story-template-div">
          {StoryData[this.state.random].story.map((word, key) => {
            return (
              <span id={key} key={key} onTouchMove={this.handleTouchMove}>
                {word}{" "}
              </span>
            );
          })}
        </div>

        <Link
          to={{
            pathname: "/professionals",
            state: {
              randomStoryIndex: this.state.random,
              wordArr: this.state.wordArr,
            },
          }}
        >
          <LightBlueButton>Bera saman</LightBlueButton>
        </Link>
      </div>
    );
  }
}

export default Story;
