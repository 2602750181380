import React from "react";
import styled from "styled-components";

const Button = styled.button`
	background-color: #2b2b2b;
	width: 80%;
	height: 60px;
	border-color: #8dca8c;
	border-radius: 30px;
	border-width: medium;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 24px;
	color: #8dca8c;
	position: fixed;
	bottom: 0;
	left: 0;
	margin-bottom: 10%;
	margin-left: 10%;
	margin-right: 10%;
`;

export const DarkGreenButton = ({ children, className }) => {
	return <Button className={className}>{children}</Button>;
};
