import React from "react";
import { Link } from "react-router-dom";
import { DarkGreenButton } from "./Buttons/DarkGreenButton.jsx";
import { StoryData } from "../data";
import { FaPhone, FaComments } from "react-icons/fa";

function Information(props) {
  return (
    <div className="page-template">
      <h2>Af hverju fer þetta yfir strikið?</h2>
      <div className="no-overflow">
        <p>{StoryData[props.location.state.randomStoryIndex].explanation}</p>
        <h2>Kannastu við þetta?</h2>
        <p>
          Það hjálpar að tala við einhvern til að átta sig á hvort það sem þú
          hefur lent í eða heyrt af sé ofbeldi og líka ef þú þarft að breyta
          þinni hegðun.
        </p>

        <div className="wrapper">
          <div className="icon-column">
            <FaComments />
          </div>
          <div className="text-column">
            <h3>Starfsfólk félagsmiðstöðva</h3>
            <p>Er alltaf til í að hlusta</p>
          </div>
        </div>

        <div className="wrapper">
          <div className="icon-column">
            <FaComments />
          </div>
          <div className="text-column">
            <h3>Foreldrar þínir</h3>
            <p>Ef þú treystir þér til að tala við þau</p>
          </div>
        </div>

        <div className="wrapper">
          <div className="icon-column">
            <FaComments />
          </div>
          <div className="text-column">
            <h3>Kennarar</h3>
            <p>Vilja vera til staðar fyrir þig</p>
          </div>
        </div>

        <div className="wrapper">
          <div className="icon-column">
            <FaComments />
          </div>
          <div className="text-column">
            <h3>Vinir þínir</h3>
            <p>Geta oft verið besta hjálpin</p>
          </div>
        </div>

        <div className="wrapper">
          <div className="icon-column">
            <FaComments />
          </div>
          <div className="text-column">
            <p>Eða einhver annar sem þér finnst gott að tala við</p>
          </div>
        </div>

        <h2>Þarft þú hjálp?</h2>
        <div className="wrapper">
          <div className="icon-column">
            <FaPhone />
          </div>
          <div className="text-column">
            <h3>112 Lögreglan</h3>
            <p>Ef þú ert í bráðri hættu</p>
          </div>
        </div>

        <div className="wrapper">
          <div className="icon-column">
            <FaPhone />
          </div>
          <div className="text-column">
            <h3>1717 Hjálparsími og netspjall Rauða Krossins</h3>
            <p>Ekkert vandamál er of stórt eða of lítið fyrir Hjálparsímann</p>
          </div>
        </div>

        <div className="wrapper">
          <div className="icon-column">
            <FaPhone />
          </div>
          <div className="text-column">
            <h3>562-6868 Stígamót</h3>
            <p>Fyrir þolendur kynbundins ofbeldis 18 ára og eldri</p>
          </div>
        </div>

        <div className="wrapper">
          <div className="icon-column">
            <FaPhone />
          </div>
          <div className="text-column">
            <h3>553-3000 Bjarkarhlíð</h3>
            <p>Fyrir þolendur ofbeldis 18 ára og eldri</p>
          </div>
        </div>

        <div className="wrapper">
          <div className="icon-column">
            <FaPhone />
          </div>
          <div className="text-column">
            <h3>530-2600 Barnavernd</h3>
            <p>Fyrir öll sem eru yngri en 18 ára</p>
          </div>
        </div>

        <div className="wrapper">
          <div className="icon-column">
            <FaPhone />
          </div>
          <div className="text-column">
            <h3>552 7878 Samtökin '78</h3>
            <p>Hagsmunafélag hinsegin fólks á Íslandi </p>
          </div>
        </div>
      </div>
      <Link to={"/story"}>
        <DarkGreenButton>Prófa aftur</DarkGreenButton>
      </Link>
    </div>
  );
}

export default Information;
